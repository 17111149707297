<!--
 * @Author       : your name
 * @Date         : 2020-11-22 11:38:07
 * @LastEditTime : 2020-11-22 14:45:02
 * @LastEditors  : Please set LastEditors
 * @Description  : In User Settings Edit
 * @FilePath     : /mingya/src/components/common/Footer.vue
-->
<template>
  <div class="footer-container .footer">
    <div v-if="isMobile" class="mobile-footer">
      <div class="footer-box">
        <div class="imgs">
          <div class="logo">
          </div>
          <div class="icons">
            <img @click="codeUrl = icon.d2_code"
                :src="icon.icon"
                alt=""
                v-for="(icon, index) of iconList"
                :key="index"
              />
          </div>
        </div>
        <div class="contact-box">
          <address class="contact">
              <img src="@/assets/img/common/address.png" alt="" />
              地址：北京市朝阳区朝外大街乙12号昆泰国际中心12层
            </address>
            <div class="contact">
              <img src="@/assets/img/common/tel.png" alt="" />
              电话：(010) 85658565 | 传真：(010) 85658566
            </div>
            <div class="contact">
              <img src="@/assets/img/common/tel.png" alt="" />
              全国统一客服热线：4001726888、4006128688
            </div>
            <div class="contact work_time">
              工作日：08:30-19:00，非工作日：09:00-12:00，13:00-18:00
            </div>
            <div class="contact">
              <img src="@/assets/img/common/tel.png" alt="" />
              全国人工回访电话：4001726888、（010）85280466
            </div>
        </div>
        <div class="copyright">京公网安备 11010502031869 号 © 2004-{{currentYear}} 明亚保险经纪版权所有 ICP证：<a href="https://beian.miit.gov.cn/" target="_blank" class="icp_red">京ICP备11027222号-3</a></div>
      </div>
      <div @click.stop="codeUrl = ''" v-if="codeUrl" class="codemodal">
        <img @click.stop="" :src="codeUrl" alt="">
      </div>
    </div>
    <footer v-else>
      <transition
        name="fadeIn"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
      >
        <div class="code" :style="codeStyle" v-if="showCode"></div>
      </transition>
      <b-container fluid class="bv-example-row">
        <b-row>
          <b-col cols="5">
            <img src="@/assets/img/common/logo-white.png" alt="" class="logo" />
            <div class="icons">
              <img
                :src="icon.icon"
                alt=""
                v-for="(icon, index) of iconList"
                :key="index"
                @mouseenter="moveIn(index)"
                @mouseleave="moveOut"
              />
            </div>
            <address class="contact">
              <img src="@/assets/img/common/address.png" alt="" />
              地址：北京市朝阳区朝外大街乙12号昆泰国际中心12层
            </address>
            <div class="contact">
              <img src="@/assets/img/common/tel.png" alt="" />
              电话：(010) 85658565 | 传真：(010) 85658566
            </div>
            <div class="contact">
              <img src="@/assets/img/common/tel.png" alt="" />
              全国统一客服热线：4001726888、4006128688<br/>
              <span class="work_time">
                工作日：08:30-19:00，非工作日：09:00-12:00，13:00-18:00
              </span>
            </div>
            <div class="contact">
              <img src="@/assets/img/common/tel.png" alt="" />
              全国人工回访电话：4001726888、（010）85280466
            </div>
          </b-col>
          <b-col cols="1">
            <p>关于明亚</p>
            <a href="#/aboutUs/introduction">明亚简介</a>
            <a href="#/aboutUs/culture">明亚文化</a>
            <a href="#/aboutUs/honor">明亚荣誉</a>
            <a href="#/aboutUs/responsibility">社会责任</a>
            <a href="#/aboutUs/news">新闻动态</a>
            <a href="#/aboutUs/media">媒体聚焦</a>
          </b-col>
          <b-col cols="1">
            <p>客户服务</p>
            <a href="#/customerService/agreement">经纪服务委托协议</a>
            <a href="#/customerService/notification">客户告知书</a>
            <a href="#/customerService/special">特色服务</a>
            <a href="#/customerService/policy">保单服务</a>
          </b-col>
          <b-col cols="1">
            <p>精英风采</p>
            <a href="#/eliteStyle/hof/member">名人堂会员</a>
            <a href="#/eliteStyle/hof/club">名人汇</a>
            <a href="#/eliteStyle/meeting">明亚高峰会</a>
            <a href="#/eliteStyle/elite">明亚精英</a>
            <a href="#/eliteStyle/life">明亚生活</a>
          </b-col>
          <b-col cols="1">
            <p>信息披露</p>
            <a href="#/mechanism">合作机构</a>
            <!-- <a href="#/website">合作网站</a> -->
            <a href="#/OnlineInsurance">互联网保险</a>
            <a href="#/disclosure">产品披露</a>
            <!-- #50667 -->
            <!-- <a href="#/systemintroduction">系统介绍</a> -->
            <a href="#/protectPersonInformation">个人信息保护</a>
          </b-col>
          <b-col cols="1">
            <p>联系我们</p>
            <a href="#/contactUs/organization">机构分布</a>
            <a href="#/contactUs/recruit">招贤纳士</a>
            <a href="#/contactUs/media">明亚自媒体</a>
          </b-col>
        </b-row>
      </b-container>
      <div class="copyright">
        <div class="copy-detail">
          <p>京公网安备 11010502031869 号  © 2004-{{currentYear}} 明亚保险经纪版权所有  ICP证：<a href="https://beian.miit.gov.cn/" target="_blank" class="icp_red">京ICP备11027222号-3</a></p>
          <!-- <p>
            <span>合作网站：</span>
            <a
              :href="partner.link"
              :key="index"
              target="blank"
              v-for="(partner, index) of partners"
              >{{ partner.name }} &nbsp;
              <span v-if="index !== partners.length - 1">|</span></a
            >
          </p> -->
        </div>
      </div>
    </footer>
  </div>

</template>

<script>
import tiktok from '@/assets/img/common/tiktok-code.png'
import weibo from '@/assets/img/common/weibo-code.png'
import wechat from '@/assets/img/common/wechat-code.png'

import { getPartnerApi } from '@/api/common'
import { getMediaApi } from '@/api/media'
import { isMobile } from '@/helper/utils'
export default {
  data () {
    let year = new Date().getFullYear()
    return {
      codeUrl: '',
      tiktok,
      weibo,
      wechat,
      codeStyle: {
        backgroundImage: `url(${tiktok})`
      },
      showCode: false,
      timer: null,
      partners: [],
      iconList: [],
      isMobile: isMobile(),
      currentYear: year
    }
  },
  methods: {
    async getMedia () {
      const { list } = await getMediaApi()
      this.iconList = list
    },
    moveIn (index) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.showCode = true
      const img = this.iconList[index].d2_code
      this.codeStyle = {
        backgroundImage: `url(${img})`
      }
    },
    moveOut () {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.showCode = false
      }, 1000)
    },
    async getPartners () {
      const { list } = await getPartnerApi()
      this.partners = list
    }
  },
  created () {
    // this.getPartners()
    this.getMedia()
  }
}
</script>

<style lang="scss" scoped>
@keyframes codeFadeIn {
  from {
    transform: scale(.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.mobile-footer {
  font-size: 50px;
  .codemodal {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.7);
    z-index: 999999999;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 70%;
      height: auto;
      transform: scale(.5);
      opacity: 0;
      animation: codeFadeIn .3s forwards;
    }
  }
  .footer-box {
    background-color: #333333;
    color: #999999;
    padding-top: .4em;
    .copyright {
      margin-top: 2em;
      font-size: 0.2em;
      box-sizing: border-box;
      height: 4.7em;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-top: 1px solid rgba(240, 236, 236, 0.486);
      padding: 0 2em;
    }
    .contact-box {
      box-sizing: border-box;
      padding: 0 0.4em;
      .contact {
        display: flex;
        align-items: center;
        font-size: 0.2em;
        margin-bottom: .4em;
        &:first-child {
          margin-bottom: .6em;
        }
        &:last-child {
          margin-bottom: 0;
        }
        img {
          width: 1.1em;
          height: auto;
          margin-right: 1.1em;
        }
        &.work_time{
          margin-left: 2.2em;
        }
      }
    }
    .imgs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.4em;
      margin-bottom: .4em;
      .logo {
        height: 0.48em;
        width: 2.5em;
        background-image: url(../../assets/img/common/logo-w.png);
        background-size: 100% 100%;
      }
      .icons {
        display: flex;
        align-items: center;
        img {
          height: 0.32em;
          width: auto;
          margin-left: 0.4em;
        }
      }
    }
  }
}
footer {
  color: #999999;
  font-size: 0.16rem;
  background-color: #2d2d2d;
  background-image: url('../../assets/img/common/footer-bg.png');
  background-size: cover;
  padding-top: 0.7rem;
  position: relative;
  .code {
    position: absolute;
    width: 2.48rem;
    height: 2.48rem;
    background-size: 100% 100%;
    position: absolute;
    z-index: 999999;
    left: 2rem;
    top: 2rem;
    background-color: #fff;
    border-radius: 5px;
    transition: all 0.8s;
  }
  .logo {
    width: 2.62rem;
    height: 0.48rem;
    margin-bottom: 0.3rem;
  }
  .bv-example-row {
    width: 14.4rem;
  }
  .icons {
    margin-bottom: 0.3rem;
    img {
      width: 0.4rem;
      height: 0.4rem;
      margin-right: 0.34rem;
      cursor: pointer;
    }
  }
  .contact {
    margin-bottom: 0.16rem;
    img {
      width: 0.17rem;
      height: 0.18rem;
      margin-right: 0.24rem;
    }
    .work_time{
      margin-left: 0.45rem;
      margin-top: 0.1rem;
      display: inline-block;
    }
  }
  a {
    display: block;
    color: #999999;
    line-height: 0.36rem;
    text-decoration: none;
    white-space: nowrap;
  }
  .container-fluid {
    padding:0;
    p {
      margin-bottom: 0.4rem;
      font-size: 0.2rem;
      color: #fff;
      font-weight: bold;
      white-space: nowrap;
    }

    .col-2,
    .col-1 {
      padding-right: calc(var(--bs-gutter-x) / 8);
      padding-left: calc(var(--bs-gutter-x) / 8);
      text-align: left;
    }
    .col-1 {
      width: 12%;
      padding-left: 0.26rem;
      &:last-child {
        padding-right: 0;
      }
    }
  }
  .col-5 {
    width: 40%;
    padding-left: 0;
  }
  .copyright {
    height: 0.95rem;
    line-height: 0.95rem;
    padding: 0;
    border-top: 1px solid #555;
    margin: 0 auto;
    margin-top: 0.6rem;
    .copy-detail {
      width: 14.4rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        margin-right: 0.1rem;
      }
    }
    p {
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
 .icp_red{
   &:hover{
    color: #ed4014;
    }
 }
}
</style>
